export const NETWORK = {
  TARGET_CHAIN_ID: 1,
  MAINNET_CHAIN_ID: 1,
  CHAIN_ID_TO_NAME: {
    1: "mainnet",
    3: "ropsten",
    4: "rinkeby",
    42: "kovan",
    1337: "localhost",
  },
  INFURA_ID: "632b4850f79f4f7f9ba2468897bdcbc0",
};

export const CONTRACTS = {
  HONEY: "Honey",
  MINTPASS: "FlowerFamMintPass",
};

export const HONEY_COIN_UNISWAP_URL = `https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x0e239dB593619bcF6248FdeF4723f26cf40e1f37`;
