export default class MintPass {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  getPrice() {
    return this.instance.passPrice();
  }

  getSupply() {
    return this.instance.totalSupply();
  }

  getMaxSupply() {
    return this.instance.maxPasses();
  }

  userLeftPasses() {
    return this.instance.userPassesLeft(this.address);
  }

  mint(signer) {
    return this.instance.connect(signer).mintOne();
  }

  mintOpen() {
    return this.instance.mintOpen();
  }
}
