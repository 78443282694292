/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { isEmpty } from "_dash";
import { ethToRegularString, getTxUrl } from "utils";
import { CONTRACTS, HONEY_COIN_UNISWAP_URL } from "global_constants";

import Faq from "components/faq";
import Honey from "./adpaters/honey";
import ProviderHOC from "hoc/provider";
import Button from "components/button";
import MintPass from "adpaters/mintPass";
import useConnect from "hooks/useConnect";
import useProvider from "hooks/useProvider";

import passGif from "assets/graphics/Card.gif";
import earthSvg from "assets/graphics/earth.svg";
import cloudsSvg from "assets/graphics/Vector-fam.svg";
import cloudBackSvg from "assets/graphics/Group-105.svg";
import footerBgSvg from "assets/graphics/footer_bg.svg";
import { ReactComponent as LogoSvg } from "assets/graphics/Logo.svg";
import { ReactComponent as StarSvg } from "assets/graphics/star.svg";
import { ReactComponent as CoinSvg } from "assets/graphics/Coin-1-2.svg";

import "./index.css";

const App = ({ provider, setProvider }) => {
  const contracts = useProvider(provider);
  const [isConnected, , doConnect, connection] = useConnect(provider);

  const [price, setPrice] = useState();
  const [txHash, setTxHash] = useState();
  const [buying, setBuying] = useState(false);
  const [isTxSuccess, setIsTxSuccess] = useState(false);

  const [supplyUsed, setSupplyUsed] = useState(1);
  const [supplyCount, setSupplyCount] = useState(269);
  const [honeyBalance, setHoneyBalance] = useState(0);

  const [soldOut, setSoldOut] = useState(0);
  const [mintOpen, setMintOpen] = useState(0);
  const [hasEnoughHoney, setHasEnoughHoney] = useState(0);

  useEffect(() => {
    if (connection) {
      setProvider(connection);
    }
  }, [connection]);

  async function getSupply() {
    const mintPassObj = new MintPass(contracts[CONTRACTS.MINTPASS]);

    const supplyUsed = await mintPassObj.getSupply();
    const maxSupply = await mintPassObj.getMaxSupply();

    setSupplyUsed(parseInt(supplyUsed));
    setSupplyCount(parseInt(maxSupply.sub(1)));
    setSoldOut(maxSupply.sub(supplyUsed).lte(0));
  }

  async function getBalance() {
    const honeyObj = new Honey(contracts[CONTRACTS.HONEY], provider.address);
    setHoneyBalance(await honeyObj.getBalance());
  }

  async function getMintOpen() {
    const mintPassObj = new MintPass(contracts[CONTRACTS.MINTPASS]);

    const mintOpen = await mintPassObj.mintOpen();
    const _blockNum = await provider.instance.getBlockNumber();
    const _block = await provider.instance.getBlock(_blockNum);

    setMintOpen(mintOpen.lte(_block.timestamp));
  }

  useEffect(() => {
    if (!isEmpty(contracts)) {
      initialize();
      const mintPassObj = new MintPass(contracts[CONTRACTS.MINTPASS]);
      mintPassObj.getPrice().then((price) => {
        setPrice(price);
      });
    }
  }, [contracts]);

  useEffect(() => {
    if (honeyBalance && price) {
      setHasEnoughHoney(honeyBalance.gte(price));
    }
  }, [price, honeyBalance]);

  function initialize(forceCheck = true) {
    getSupply();
    getBalance();
    if (forceCheck) {
      getMintOpen();
    }
  }

  function getConnectedBtnText() {
    let btnText = "";
    if (isTxSuccess) {
      btnText = "Success";
    } else {
      if (mintOpen) {
        if (soldOut) {
          btnText = "Honeylist mint sold out";
        } else {
          if (hasEnoughHoney) {
            btnText = "Get yours now!";
          } else {
            btnText = "Not enough Honey";
          }
        }
      } else {
        btnText = "Starts at 6PM CEST";
      }
    }
    return btnText;
  }

  async function onBuy() {
    if (hasEnoughHoney && !soldOut && mintOpen && !isTxSuccess) {
      setBuying(true);
      setTxHash(undefined);
      try {
        const mintObj = new MintPass(
          contracts[CONTRACTS.MINTPASS],
          provider.address
        );
        const tx = await mintObj.mint(provider.signer);
        setTxHash(tx.hash);
        await tx.wait();
        setIsTxSuccess(true);
      } catch (e) {
        alert(e.message);
      }

      initialize(false);
      setBuying(false);
    }
  }

  return (
    <div className="h-full">
      <div
        className="mt-12 px-2"
        style={{ backgroundImage: `url(${cloudBackSvg})` }}
      >
        <LogoSvg className="m-auto" />
        <div className="flex mt-20 justify-center relative items-center">
          <img
            src={passGif}
            alt="FlowerFam Pass"
            className="w-[500px] rotate-[-10deg] relative mr-[-100px] z-[1] top-[-30px] md:block hidden"
          />
          <div className="flex-1 max-w-[700px] rounded-[40px]">
            <div className="bg-white rounded-[40px] relative md:p-8 p-2">
              <div className="relative md:mt-[-130px] mt-[-60px]">                
                <p className="font-arco text-8xl title-stroke text-white">
                  <span className="text-[#ffec3e]">honey</span> list sold out!
                </p>
              </div>
              <div className="p-8 md:px-10 px-4">
                <div className="flex items-center gap-4">
                  <div className="flex">
                    {Array(5)
                      .fill(0)
                      .map((_, idx) => {
                        return <StarSvg key={idx} />;
                      })}
                  </div>
                  <p className="font-morh-sb text-textColor text-base">
                    (Rating of our Pioneers)
                  </p>
                </div>
                <p className="text-textColor text-lg font-morh-m mt-6">
                  A Honeycard will give you{" "}
                  <span className="font-morh-sb">
                    exclusive access <br />
                    to mint 2 Flower Fam NFTs on May 21st!
                  </span>
                </p>
                <div className="flex mt-8 gap-6">
                  <div>
                    <p className="font-morh-sb mb-2">Price</p>
                    <div className="flex bg-red-100 rounded-lg py-2 px-4 gap-2">
                      <CoinSvg />
                      <p className="text-red-600 text-2xl font-morh-b">
                        {(price && ethToRegularString(price)) || 169}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="font-morh-sb mb-2">Stock left</p>
                    <p className="bg-gray-100 py-2 px-4 text-2xl text-textColor font-morh-b rounded-lg">
                      0/{supplyCount}
                    </p>
                  </div>
                </div>
                <div className="px-0 absolute uppercase bottom-[-20px] flex gap-4">
                  {isConnected ? (
                    <>
                      <Button
                        isPrimary
                        loading={buying}
                        onClick={() => onBuy()}
                      >
                        {getConnectedBtnText()}
                      </Button>
                      {txHash ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={getTxUrl(txHash)}
                          className="font-morh-sb text-xl"
                        >
                          View TX
                        </a>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <Button
                      isPrimary
                      onClick={() => doConnect()}
                      classes={["!bg-[#ffec3e]", "text-textColor"]}
                    >
                      Connect wallet
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-9 flex gap-2 items-end ml-16">
              <a
                target="_blank"
                rel="noreferrer"
                href={HONEY_COIN_UNISWAP_URL}
                className="text-base font-morh-sb text-textColor underline"
              >
                Click here to buy
              </a>
              <span className="font-arco text-white text-lg">Honeycoin</span>
            </div>
          </div>
        </div>
        <div className="flex font-morh-sb justify-center gap-2 items-start">
          <div className="bg-[#ffecfa] text-[#bf42ad] px-2 rounded-lg">
            100%
          </div>
          <p className="text-lg text-center">
            of the proceedings to back in the liquidity pool of <br />
            $honeycoin
          </p>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${earthSvg})` }}
        className="h-[600px] w-full mt-12 bg-cover"
      />
      <div
        style={{
          backgroundPosition: "50% 100%",
          backgroundImage: `url(${cloudsSvg})`,
        }}
        className="h-[250px] w-full relative top-[-150px] bg-cover bg-no-repeat"
      />
      <Faq />
      <div
        style={{
          backgroundPosition: "50% 0%",
          backgroundImage: `url(${footerBgSvg})`,
        }}
        className="h-[600px] w-full bg-cover mt-[-180px] bg-no-repeat"
      />
    </div>
  );
};

export default ProviderHOC(App);
