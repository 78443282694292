import { useState } from "react";
import { ReactComponent as FaqIconSvg } from "assets/graphics/_.svg";

const FAQ_ITEMS = [
  {
    title: "What’s the mint price?",
    content: <p>0.069ETH</p>,
  },
  {
    title: "How many flowers are there?",
    content: (
      <p>
        6969 Flowers live in the Oasis.
      </p>
    ),
  },
  {
    title: "Where can I buy $honeycoin?",
    content: (
      <p style={{cursor: "pointer", textDecoration: "underline" }} onClick={() => window.open("https://docs.flowerfam.earth/before-you-leave.../tutorial", "_blank")}>
        Here
      </p>
    ),
  },
  {
    title: "Wen can I mint?",
    content: <p>Stage 1. 09:00-10:18 PT</p>,
  },
  {
    title: "How many NFTs can I mint?",
    content: (
      <p>
        Honeylist = 2 NFTs
      </p>
    ),
  },
  {
    title: "Wen stake?",
    content: <p>You can mint+stake in one transaction. The Oasis is live from Day 1.</p>,
  },
  {
    title: "How’s $honeycoin doing?",
    content: (
      <p style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => window.open("https://www.dextools.io/app/ether/pair-explorer/0xdf44622fc41d706d6d3cfe1d22dcf80b170c2ab2", "_blank")}>
        Check here.
      </p>
    ),
  },
  {
    title: "I am already on the Flower List. Can I still get access. ",
    content: <p>Yes. You can even use the same wallet.</p>,
  },
  {
    title: "Who did the audit?",
    content: <p>
        Our frens over at <a href="https://blockapex.io/smart-contract-audit/" target="_blank" rel="noreferrer">BlockApex</a>. 
    </p>,
  },
  {
    title: "Do Flowers like gas fees?",
    content: <p>
        No they prefer ERC-721A and an optimised contract. 
    </p>,
  },
  {
    title: "Do I own the IP rights of my flowers?",
    content: <p>
        Yes. Do whatever you want with it.
    </p>,
  },
];

export default function Faq() {
  const [openFaqItems, setOpenFaqItems] = useState([]);

  function onToggleFaqItem(idx) {
    const index = openFaqItems.indexOf(idx);
    if (index > -1) {
      setOpenFaqItems(openFaqItems.filter((i) => i !== idx));
    } else {
      setOpenFaqItems([...openFaqItems, idx]);
    }
  }

  return (
    <div className="pt-16 pb-52 gradient-background mt-[-150px] px-2">
      <div className="container m-auto">
        <p className="font-arco text-7xl title-stroke text-white text-center mb-12">
          faq
        </p>
        <div className="faq-wrapper max-w-[800px] m-auto">
          {FAQ_ITEMS.map((item, idx) => {
            const isOpen = openFaqItems.indexOf(idx) > -1;
            return (
              <div key={idx} className="faq-item bg-white rounded-3xl my-6 border-4 border-textColor">
                <div
                  onClick={() => onToggleFaqItem(idx)}
                  className="faq-title px-10 pt-6 pb-5 cursor-pointer flex justify-between"
                >
                  <p className="font-morh-b text-xl text-textColor">
                    {item.title}
                  </p>
                  <FaqIconSvg
                    className={`toggle-faq-icon transition-all ${
                      isOpen ? "active" : ""
                    }`}
                  />
                </div>
                <div
                  className={`faq-content font-morh-m pb-6 px-10 text-textColor ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  {item.content}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
