export default class Honey {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  getBalance() {
    return this.instance.balanceOf(this.address);
  }
}
